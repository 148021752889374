.sidebar-container {
  display: flex;
  align-items: center;
  position: relative;
}

.sidebar {
  background: linear-gradient(135deg, #1a1a1a 0%, #2d2d2d 100%);
  width: 250px;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  transform: translateX(-100%);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 1000;
  box-shadow: 4px 0 15px rgba(0, 0, 0, 0.2);
}

.sidebar.is-open {
  transform: translateX(0);
}

.content.is-open {
  margin-left: 250px !important;
}

/* Header Styles */
.sidebar-header {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.logo-container {
  display: flex;
  align-items: center;
  gap: 12px;
}

.logo {
  width: 32px;
  height: 32px;
  transition: transform 0.3s ease;
}

.logo-text {
  color: #fff;
  font-size: 1.2rem;
  font-weight: 600;
  letter-spacing: -0.02em;
}

.close-btn {
  color: rgba(255, 255, 255, 0.7);
  font-size: 1.2rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.close-btn:hover {
  color: #fff;
  transform: rotate(90deg);
}

/* Menu Items */
.sidebar-content {
  padding: 15px 0;
}

.menu-item {
  display: flex;
  align-items: center;
  padding: 12px 20px;
  color: rgba(255, 255, 255, 0.7) !important;
  transition: all 0.3s ease;
  margin: 4px 10px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.menu-item::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 3px;
  background: #0d6efd;
  transform: scaleY(0);
  transition: transform 0.2s ease;
}

.menu-item:hover {
  background: rgba(255, 255, 255, 0.1);
  color: #fff !important;
  transform: translateX(5px);
}

.menu-item:hover::before {
  transform: scaleY(1);
}

.menu-item.active {
  background: rgba(255, 255, 255, 0.15);
  color: #fff !important;
}

.menu-item.active::before {
  transform: scaleY(1);
}

.icon {
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  margin-right: 12px;
  transition: all 0.3s ease;
}

.menu-item:hover .icon {
  transform: scale(1.1);
}

.title {
  font-size: 0.95rem;
  font-weight: 500;
}

/* Logout Button */
.logout {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  margin: 0 10px;
  padding: 12px 20px;
  display: flex;
  align-items: center;
  color: #ff4d4d;
  cursor: pointer;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.logout:hover {
  background: rgba(255, 77, 77, 0.15);
  transform: translateX(5px);
}

.logout .icon {
  color: #ff4d4d;
}

/* Animations */
@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(-10px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.menu-item {
  animation: slideIn 0.3s ease forwards;
  animation-delay: calc(var(--index) * 0.1s);
}
