.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.7); /* Slight transparency */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it appears above other elements */
  pointer-events: none; /* Disable interaction */
}

.loader-container > * {
  pointer-events: all; /* Allow interaction with the spinner itself */
}
