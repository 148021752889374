.App {
  display: flex;
  width: 100%;
  align-items: stretch;
}

/* @import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700"; */
/* @import "https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,500,600,700"; */

body {
  /*font-family: 'Poppins', sans-serif;*/
  font-family: "Nunito Sans", sans-serif;
  /*background: #fafafa;*/
  background-color: #f5f9fc;
}

a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.2s;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
.sidebar {
  min-width: 250px;
  max-width: 250px;
  background: #354052;
  color: #adb5bd;
  margin-left: -250px;
  transition: all 0.5s;
}

.sidebar.is-open {
  margin-left: 0;
  transition: 0.5s;
}

.sidebar-header {
  background: #313b4c;
  color: #adb5bd;
}

.sidebar-header h3 {
  color: #fff;
  padding: 1em;
}

.sidebar ul p {
  color: #fff;
  padding: 10px;
}

.collapse.show,
.collapsing {
  background: #313b4c;
}

.nav-item {
  /*color: #ced4da;*/
  color: #adb5bd;
  font-weight: 300;
}

.nav-item.open {
  color: #e9ecef;
  background: #313b4c;
}

.nav-item.active {
  color: #e9ecef;
  background: #2d3646 !important;
}

.nav-item:hover {
  background: #313b4c;
  color: #fff;
  transition: 0.2s;
}

.nav-item .svg-inline--fa {
  color: inherit;
}

li a.dropdown-toggle::after {
  display: inline-flex;
  position: relative;
  left: 60%;
  top: 10%;
}

.sidebar-header > button {
  position: relative;
  float: right;
  margin: 0.5em;
  font-size: 2rem;
  cursor: pointer;
  display: none;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
.content {
  padding: 20px;
  margin-left: 0;
  height: 100vh;
}

@media only screen and (max-width: 575.98px) {
  body {
    overflow: hidden;
  }

  .content.is-open {
    /*margin-left: 100%;*/
    display: none;
    transition: all 0.5s;
  }

  .sidebar.is-open {
    min-width: 100%;
    max-width: 100%;
    margin-left: 0;
    transition: all 0.5s, height 0s;
    height: 100vh !important;
  }

  .sidebar.is-open > .sidebar-header button {
    display: block;
  }

  li a.dropdown-toggle::after {
    display: inline-block;
    position: relative;
    left: 68%;
  }
}
.sidebar-item {
  color: #fff !important;
  font-weight: 500 !important;
}
.mr-2 {
  margin-right: 10px;
}
.stories-list-item {
  padding: 15px;
  background-color: #e8e6e6;
  border-radius: 5px;
  margin-top: 5px;
}

.height-50-width-50 {
  height: 50px;
  width: 50px;
}
.d-flex {
  display: flex;
}
.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}
.ml-5 {
  margin-left: 5px !important;
}
.mr-10 {
  margin-right: 10px;
}
.p-10 {
  padding: 10px;
}
.mw-350 {
  max-width: 350px;
}
.mt-20 {
  margin-top: 20px !important;
}
.float-right {
  float: right;
}
.form-title {
  font-weight: 600;
}
.content-overflow {
  height: 100vh;
  overflow: scroll;
}

.content-between {
  justify-content: space-between;
}
.action-width {
  max-width: 200px;
}
.font-bold {
  font-weight: 600;
}
.color-white {
  color: #ffffff;
}
.chapter-content {
  max-width: 500px;
}
.select-box {
  height: 200px;
  overflow-y: scroll;
  min-width: 230px;
  padding: 5px 9px;
}
.selected-options {
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem; /* Space between tags */
}

.selected-tag {
  background-color: #e9ecef;
  border-radius: 20px;
  padding: 0.25rem 0.75rem;
  display: flex;
  align-items: center;
  font-size: 0.875rem;
}

.remove-btn {
  background: none;
  border: none;
  margin-left: 0.5rem;
  font-size: 1rem;
  cursor: pointer;
}

.remove-btn:hover {
  color: red;
}

.sidebar {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.sidebar-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.logout {
  padding: 10px;
  text-align: center;
  cursor: pointer;
  color: #fff;
}
.pt-0 {
  padding-top: 0px;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.9);
}

.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 20px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
